import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import useFetch from "../../../components/customHooks/useFetch";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const NominationForm = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
  }
  const { id } = useParams();

  const { data: award } = useFetch(`/api/v2/guest/nomination/award/${id}`);

  const ref = useRef();
  const [isloading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const navigate = useNavigate();
  const [nomineePhone, setNomineePhone] = useState("");
  const [categories, setCategories] = useState();
  const [paymentMethod, setPaymentMethod] = useState("momo");
  const [momoNumber, setMomoNumber] = useState("");
  const [errors, setErrors] = useState();
  const [userInput, setUserInput] = useState({
    social_media: "",
    other_info: "", // 'in:unpaid,pending,active,rejected,blocked'],
    full_name: "",
    stage_name: "",
    gender: "",
    award_id: id,
    category_id: "",
    category_cost: "",
    network: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [selectedCategory, setSelectedCategory] = useState({});
  const handleCategorySelected = (selectedList, selectedItem) => {
    setUserInput((userInput) => ({
      ...userInput,
      category_id: selectedItem.id,
      category_cost: selectedItem.price,
    }));
    setSelectedCategory(selectedItem);
    ref.current.focus();
  };

  useEffect(() => {
    setCategories(award?.categories);
  }, [award]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.category_id === "") {
      window.alert("Please Select Nominee Category");
    } else if (
      (nomineePhone === "233" ||
        nomineePhone === "" ||
        nomineePhone.length < 12) &&
      paymentMethod === "momo"
    ) {
      window.alert("please Enter a valid phone number");
    } else if (
      (momoNumber === "233" || momoNumber === "" || momoNumber.length < 12) &&
      userInput?.category_cost > 0 &&
      paymentMethod === "momo"
    ) {
      window.alert("please Enter a valid mobile money number");
    } else {
      setLoading(true);
      var data = new FormData();
      data.append("full_name", userInput.full_name);
      data.append("gender", userInput.gender);
      data.append("phone", nomineePhone);
      selectedImage && data.append("image", selectedImage);
      data.append("award_id", id);
      data.append("category_id", userInput.category_id);
      data.append("category_cost", Number(userInput.category_cost));
      data.append("stage_name", userInput.stage_name);
      if (userInput?.category_cost > 0) {
        data.append("payment_mode", paymentMethod);
        data.append("network", userInput.network);
        data.append("momo_number", momoNumber);
      }

      axios
        .post("/api/v2/guest/nomination/create", data)
        .then((res) => {
          if (
            paymentMethod === "momo" &&
            userInput?.category_cost > 0 &&
            res?.data?.status === 200 &&
            res?.data?.success === true
          ) {
            Swal.fire({
              title: "Success",
              text: "To Complete Payment:You'll Recieve A Prompt To Enter Your Momo Pin, if MTN Prompt Delays, Dial *170#, Select Option 6, And Then Option 3 To Approve This Transaction.",
              icon: "success",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            }).then(() => {
              navigate(-1);
            });
          } else if (
            paymentMethod === "card" &&
            userInput?.category_cost > 0 &&
            res?.data?.status === 200 &&
            res?.data?.success === true
          ) {
            window.location.replace(res?.data?.data?.authorization_url);
          } else if (res?.data?.status === 200 && res?.data?.success === true) {
            setLoading(false);
            let msg = res.data.msg;
            Swal.fire({
              title: "Success",
              text: msg,
              icon: "success",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            }).then(() => {
              navigate(-1);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
        });
    }
  };

  return (
    <>
      <Nav />
      <div className="award-div">
        <div className="award-img-title">
          <h3 className="text-white text-capitalize">{award?.title}</h3>
          <h1 className="text-white">Nomination Forms</h1>
        </div>
      </div>
      <div className="advert-margin d-md-flex card-margin-top1">
        <div className="col-lg-4 col-md-4 col-12">
          <div className="card card-shadow">
            <div className="card-body">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
                alt="arrow"
                className="nom-height"
              />
            </div>
            <div className="card-title m-3">
              <div className="mb-0">Nomination Ends on</div>
              <div className="mb-2">
                <b>{award?.end_date}</b>
              </div>
              <div className="mb-2">
                Available Slots:{" "}
                <b>{`${
                  Number(selectedCategory?.capacity) -
                    Number(selectedCategory?.occupied) || "N/A"
                }`}</b>
              </div>
              <div className="mb-2">
                Gender Group:{" "}
                <b className="text-capitalize">{`${
                  selectedCategory?.gender || "N/A"
                }`}</b>
              </div>
              <div className="mb-2">
                Nomination Fee (GHS) :
                <b className="text-capitalize">{`${
                  selectedCategory?.price || 0
                }`}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-12">
          <div className="card card-shadow">
            <div className="card-header">
              <h3>Add Nominee</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row formtype">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Full Name *</label>
                            <input
                              className="form-control"
                              type="text"
                              value={userInput?.full_name}
                              name="full_name"
                              onChange={handleUserInput}
                              required
                            />

                            <small className="text-danger">
                              {errors?.full_name}
                            </small>
                          </div>
                        </div>

                        <div className="col-md-6 ">
                          <div className="form-group">
                            <label>Select Category *</label>
                            <Multiselect
                              options={categories}
                              className="multiselect"
                              selectionLimit={1}
                              displayValue="title"
                              // placeholder="Select Catefory"
                              emptyRecordMsg="Category Not Found"
                              closeOnSelect={true}
                              onSelect={handleCategorySelected}
                            />
                            <div className="text-danger">
                              {errors?.category_id}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Gender*</label>
                            <select
                              className="form-control"
                              ref={ref}
                              id="sel2"
                              name="gender"
                              value={userInput?.gender}
                              onChange={handleUserInput}
                              required
                            >
                              <option value="">--Select Gender--</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <small className="text-danger">
                              {errors?.gender}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Stage Name (optional)</label>
                            <input
                              className="form-control"
                              type="text"
                              value={userInput?.stage_name}
                              name="stage_name"
                              onChange={handleUserInput}
                            />

                            <small className="text-danger">
                              {errors?.stage_name}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="text-left">
                              Enter Mobile Money Number
                            </div>
                            <PhoneInput
                              country={"gh"}
                              // onlyCountries={["gh"]}
                              value={nomineePhone}
                              onChange={setNomineePhone}
                              countryCodeEditable={false}
                              required={true}
                              className=""
                            />
                            <small className="text-danger">
                              {errors?.phone}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Social Media (Optional)</label>
                            <input
                              type="text"
                              className="form-control datetimepicker"
                              name="social_media"
                              value={userInput?.social_media}
                              onChange={handleUserInput}
                            />
                            <small className="text-danger">
                              {errors?.phone}
                            </small>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Other Info (Optional)</label>
                            <input
                              type="text"
                              className="form-control datetimepicker"
                              name="other_info"
                              value={userInput?.other_info}
                              onChange={handleUserInput}
                            />
                            <small className="text-danger">
                              {errors?.phone}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Upload Image *</label>
                            <div className=" mb-3">
                              <input
                                type="file"
                                name="image"
                                required
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                              />
                            </div>
                            <div className="text-danger">{errors?.image}</div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          {selectedImage && (
                            <div
                              style={{
                                border: "1px solid black",
                                maxWidth: "40%",
                                maxHeight: "150%",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                width="100%"
                              />
                            </div>
                          )}
                        </div>

                        {userInput?.category_cost > 0 ? (
                          <div className="share-content">
                            <div className="mb-2">
                              <div className="row">
                                <div className="col-md-6">
                                  <label htmlFor="Direct">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="phone"
                                        value="momo"
                                        id="Direct"
                                        defaultChecked
                                        onChange={(e) =>
                                          setPaymentMethod(e.target.value)
                                        }
                                      />
                                      <h3 className="my-auto text-nowrap ms-2">
                                        Direct Momo Payment
                                      </h3>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="email1" className="ms-md-4">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        name="phone"
                                        value="card"
                                        id="email1"
                                        onChange={(e) =>
                                          setPaymentMethod(e.target.value)
                                        }
                                      />
                                      <h3 className="my-auto ms-2 text-nowrap">
                                        Alternate Payment
                                      </h3>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {paymentMethod === "momo" && (
                              <div className="container justify-content-left d-flex">
                                <small className="text-left text-left-f">
                                  Direct Payment allows you to make payment
                                  directly from your momo wallet
                                </small>
                              </div>
                            )}
                            {paymentMethod === "card" && (
                              <div className="container justify-content-left d-flex">
                                <small className="text-left text-left-f">
                                  Alternate Payment allows you make payment via
                                  Credit Card,Master Card or GhQR
                                </small>
                              </div>
                            )}
                            {paymentMethod === "momo" && (
                              <>
                                <div className="mb-3 mt-3">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      required
                                      id="sel2"
                                      name="network"
                                      value={userInput?.network}
                                      onChange={handleUserInput}
                                    >
                                      <option value="">
                                        ---Select Network---
                                      </option>
                                      <option value="MTN-GH">MTN-GH</option>
                                      <option value="VODAFONE-GH">
                                        VODAFONE-GH
                                      </option>
                                      <option value="TIGO-GH">TIGO-GH</option>
                                      <option value="AIRTEL-GH">
                                        AIRTEL-GH
                                      </option>
                                    </select>
                                  </div>
                                  <small className="text-danger">
                                    {errors?.network}
                                  </small>
                                </div>
                                <div className="mb-3">
                                  <div className="form-group">
                                    <div className="text-left">
                                      Enter Mobile Money Number
                                    </div>
                                    <PhoneInput
                                      country={"gh"}
                                      onlyCountries={["gh"]}
                                      value={momoNumber}
                                      onChange={setMomoNumber}
                                      countryCodeEditable={false}
                                      required={true}
                                      className=""
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mt-3">
                          {userInput?.category_cost > 0 ? (
                            <Button
                              buttonText={"Make Payment"}
                              isloading={isloading}
                              cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                            />
                          ) : (
                            <Button
                              buttonText={"Save Nominee Details"}
                              isloading={isloading}
                              cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NominationForm;
